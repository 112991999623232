











  import {
    SnippetCallbacks,
    SnippetConfig,
    XEvent,
    XEventsTypes,
    XOn,
    XProvide
  } from '@empathyco/x-components';
  import { PreselectedFilters } from '@empathyco/x-components/facets';
  import { Tagging } from '@empathyco/x-components/tagging';
  import { UrlHandler } from '@empathyco/x-components/url';
  import { SnippetConfigExtraParams } from '@empathyco/x-components/extra-params';
  import { Component, Inject, Provide, Vue, Watch } from 'vue-property-decorator';
  import { Result } from '@empathyco/x-types';
  import { QueryPreviewInfo } from '@empathyco/x-components/queries-preview';
  import { useDevice } from './composables/use-device.composable';
  import currencies from './i18n/currencies';
  import './tailwind/index.css';
  // eslint-disable-next-line max-len
  import CustomSnippetConfigExtraParams from './x-components/custom-snippet-config-extra-params.vue';

  @Component({
    components: {
      CustomSnippetConfigExtraParams,
      SnippetCallbacks,
      SnippetConfigExtraParams,
      Tagging,
      PreselectedFilters,
      UrlHandler,
      MainModal: () => import('./components/custom-main-modal.vue').then(m => m.default)
    }
  })
  export default class App extends Vue {
    protected isOpen = false;

    @XOn(['UserOpenXProgrammatically', 'UserClickedOpenX'])
    open(): void {
      this.isOpen = true;
    }

    @Inject('snippetConfig')
    protected snippetConfig!: SnippetConfig;

    protected device = useDevice();

    protected get documentDirection(): string {
      return (
        document.documentElement.dir ||
        document.body.dir ||
        (this.snippetConfig.documentDirection ?? 'ltr')
      );
    }

    protected add2CartCallbackEvents: XEvent[] = [
      'UserClickedResultAddToCart',
      'UserClickedResultRemoveFromCart'
    ];

    @XOn(['SnippetCallbackExecuted'])
    callbackExecuted(payload: XEventsTypes['SnippetCallbackExecuted']): void {
      if (this.add2CartCallbackEvents.includes(payload.event)) {
        const cartResult = this.snippetConfig.cart?.products?.find(
          product => product[(payload.payload as Result).id]
        );
        const hasStock = cartResult ? Object.values(cartResult)[0].inStock : undefined;

        if (hasStock === false) {
          this.$x.emit('UserModifiedAdd2cart', {
            id: (payload.payload as Result).id,
            availability: false
          });
        }
      }
    }

    public excludedExtraParams = [
      'callbacks',
      'productId',
      'uiLang',
      'consent',
      'documentDirection',
      'filters',
      'isSpa',
      'queriesPreview',
      'cart',
      'wishlist'
    ];

    @Provide('currencyFormat')
    public get currencyFormat(): string {
      return currencies[this.snippetConfig.currency!];
    }

    @XProvide('queriesPreviewInfo')
    public get queriesPreviewInfo(): QueryPreviewInfo[] | undefined {
      return this.snippetConfig.queriesPreview;
    }

    @Watch('snippetConfig.uiLang')
    syncLang(uiLang: string): void {
      this.$setLocale(uiLang);
    }

    @Watch('device.deviceName')
    syncDevice(deviceName: string): void {
      this.$setLocaleDevice(deviceName);
    }
  }
