import { namespacedWireCommit, XModuleOptions } from '@empathyco/x-components';
import { Result } from '@empathyco/x-types';

const updateResultOutOfStock = namespacedWireCommit('search')(
  'updateResult',
  ({ eventPayload: result }: { eventPayload: Result }) => {
    return {
      id: result.id,
      availability: result.availability
    };
  }
);

export const searchInstallOptions: XModuleOptions<'search'> = {
  wiring: {
    UserModifiedAdd2cart: {
      updateResultOutOfStock
    },
    UserSelectedAHistoryQuery: {
      //@ts-ignore
      setSearchSelectedFiltersFromHistoryQuery: undefined
    }
  }
};
